<template>
  <div>
    <b-modal
      id="add-new-status-sidebar"
      centered
      :visible="isAddNewStatusSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :title="$t('statusAdd')"
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankStatus)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-status-sidebar-active', val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- statusdate -->
          <validation-provider
            #default="validationContext"
            name="statusdate"
            rules="required"
          >
            <b-form-group :label="$t('statusDateTime')" label-for="statusdate">
              <b-form-datepicker
                v-model="blankStatus.statusdate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group :label="$t('status')" label-for="status">
              <v-select
                v-model="blankStatus.statusid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statuses"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
import vSelect from "vue-select";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,

    BFormInvalidFeedback,
    BFormDatepicker,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewStatusSidebarActive",
    event: "update:is-add-new-status-sidebar-active",
  },
  props: {
    isAddNewStatusSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      axios: this.$http,
    };
  },

  setup(props, { emit, http }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const statuses = ref([]);

    const blankStatusData = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      animalid: props.selectedAnimal.id,
      statusdate: dayJs().format(),
      statusid: 0,
    };

    const getStatusList = async () => {
      if (store.state.definitionsModule.statusData.length == 0) {
        await store.dispatch("definitionsModule/fetchStatuses");
      }
      const stts = store.state.definitionsModule.statusData;
      statuses.value = stts.map((m) => ({
        label: m.name,
        value: m.id,
      }));
    };

    getStatusList();

    const blankStatus = ref(JSON.parse(JSON.stringify(blankStatusData)));
    const resetblankStatus = () => {
      blankStatus.value = JSON.parse(JSON.stringify(blankStatusData));
    };

    const onSubmit = (status) => {
      store.dispatch("animalsModule/addAnimalStatus", status).then((res) => {
        if (res.statusdata == "-2") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalAlreadyAdded"),
              icon: "AlertCircleIcon",
              variant: "alert",
            },
          });
        } else if (res.statusdata == "1") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
          emit("refetch-data");
          emit("update:is-add-new-status-sidebar-active", false);
        } else {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalStatusNotAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        }
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankStatus);
    return {
      blankStatus,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      statuses,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-status-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
