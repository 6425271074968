<template>
  <div>
    <b-modal
      id="eedit-status-sidebar"
      centered
      :visible="isEditStatusSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankStatusData)"
      @hidden="formValidation(resetblankstatus).resetForm"
      @change="(val) => changed(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ $t("editAnimalStatus") }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->

          <b-form
            ref="refForm"
            class="p-2 modal-form"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="formValidation(resetblankstatus).resetForm"
          >
            <button ref="submitButtonRef" type="submit" class="d-none" />

            <!-- statusdate -->
            <validation-provider
              #default="validationContext"
              name="statusdate"
              rules="required"
            >
              <b-form-group
                :label="$t('statusDateTime')"
                label-for="statusdate"
              >
                <b-form-datepicker
                  v-model="blankStatusData.statusdate"
                  :locale="$i18n.locale"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Status -->
            <validation-provider
              #default="validationContext"
              name="status"
              rules="required"
            >
              <b-form-group :label="$t('status')" label-for="status">
                <v-select
                  v-model="blankStatusData.statusid"
                  transition=""
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statuses"
                  class="w-100"
                  :reduce="(val) => val.value"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t("edit") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t("cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditStatusSidebarActive",
    event: "update:is-edit-status-sidebar-active",
  },
  props: {
    isEditStatusSidebarActive: {
      type: Boolean,
      required: true,
    },
    status: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankStatusData: { ...this.status },
      formValidation: formValidation,
      statuses: [],
    };
  },
  mounted() {
    this.getStatusList();
  },
  async created() {},
  methods: {
    async getStatusList() {
      if (this.$store.state.definitionsModule.statusData.length == 0) {
        await this.$store.dispatch("definitionsModule/fetchStatuses");
      }
      const stts = this.$store.state.definitionsModule.statusData;
      this.statuses = stts.map((m) => ({
        label: m.name,
        value: m.id,
      }));
    },
    async changed(val) {
      this.resetblankstatus();
      this.$emit("update:is-edit-status-sidebar-active", val);
    },
    onSubmit(status) {
      store.dispatch("animalsModule/updateAnimalStatus", status).then(() => {
        this.$emit("refetch-data");
        this.$emit("update:is-edit-status-sidebar-active", false);
      });
    },
    async resetblankstatus() {
      this.blankStatusData = {
        ...this.status,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-status-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
