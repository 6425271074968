<template>
  <div>
    <animal-list-status
      v-if="animal.id != null"
      :key="animal.id"
      :selected-animal="animal"
    />
  </div>
</template>

<script>
import AnimalListStatus from "./components/AnimalStatus/AnimalListStatus.vue";
import { mapState } from "vuex";
export default {
  components: { AnimalListStatus },
  computed: {
    ...mapState({
      animal: (state) => state.animalsModule.selectedAnimal || {},
    }),
  },
};
</script>

<style></style>
